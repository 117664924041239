/*using fonts*/
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on December 17, 2015 */



@font-face {
  font-family: 'geometriamedium';
  src: url('../fonts/geometria-medium-webfont.eot');
  src: url('../fonts/geometria-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/geometria-medium-webfont.woff2') format('woff2'),
  url('../fonts/geometria-medium-webfont.woff') format('woff'),
  url('../fonts/geometria-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'geometriaregular';
  src: url('../fonts/geometria-webfont.eot');
  src: url('../fonts/geometria-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/geometria-webfont.woff2') format('woff2'),
  url('../fonts/geometria-webfont.woff') format('woff'),
  url('../fonts/geometria-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('../fonts/mark_simonson_-_proxima_nova_regular-webfont.eot');
  src: url('../fonts/mark_simonson_-_proxima_nova_regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/mark_simonson_-_proxima_nova_regular-webfont.woff2') format('woff2'),
  url('../fonts/mark_simonson_-_proxima_nova_regular-webfont.woff') format('woff'),
  url('../fonts/mark_simonson_-_proxima_nova_regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'proxima_nova_ltsemibold';
  src: url('../fonts/mark_simonson_-_proxima_nova_semibold-webfont.eot');
  src: url('../fonts/mark_simonson_-_proxima_nova_semibold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/mark_simonson_-_proxima_nova_semibold-webfont.woff2') format('woff2'),
  url('../fonts/mark_simonson_-_proxima_nova_semibold-webfont.woff') format('woff'),
  url('../fonts/mark_simonson_-_proxima_nova_semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}



@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot);
  src: url(../fonts/fontawesome-webfont.ttf);
  src: url(../fonts/fontawesome-webfont.woff);
}

/*-----------*/

*{
  font-family: 'proxima_nova_rgregular';
}

body{
  padding: 0;
  margin: 0;
}

.epn-wrapper{
  overflow: hidden;
}


ul{
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style-type: none;
}

.breadcrumb-delimiter{
  font-family:FontAwesome;
  color: #d8d8d8;
  font-size: 13px;
  position: relative;
  top: 3px;
}

.bread-crumbs{
  clear: both;
  width: 1170px;
  margin: 0 auto;
  padding-left: 25px;
  margin-top: 45px;

  li{
    float:left;
    margin: 0 15px 0 15px;

    a{
      text-decoration: none;
      color:#0277ff;
    }
  }

  span{
    float:left;
  }
}

.B_lastCrumb a{
  color: #9b9b9b !important;
  pointer-events: none;
  cursor: default;
}

.germany-flag{
  width:90px;
  height:95px;
  background-color:#f6f6f5;
  text-align: center;
}

.germany-flag::after{
  content:'';
  border: 45px solid transparent;	border-top: 20px solid #f6f6f5;
  position: relative;
  top:19px;
}

.germany-flag .flag div{
  width:50px;
  height:12px;
  margin-left: 20px;
}

.flag{
  padding-top: 15px;
  padding-bottom: 5px;
}

.germany-flag span{
  font-family: 'proxima_nova_ltsemibold';
  font-size: 13px;
}

.red-color{
  color:#d73247;
}

.fotorama{
  width: 655px;
}

/*стили для кастомного file input*/
.file_upload{
  position: relative;
  overflow: hidden;
  font-size: 1em;
  line-height: 2em;
  background-color: #fff;
  height: 50px;
  width: 350px;
}
.file_upload > #file-button{
  background: #737373;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'proxima_nova_ltsemibold';
  font-size: 18px;
  float: right;
  width: 96px;
  height: 100%;
  padding: 9px 0px 0px 24px;
}
.file_upload > #file-placeholder{
  font-family: 'proxima_nova_rgregular';
  font-size: 14px;
  color:#c0c0c0;
  margin: 10px 0px 0px 15px;
}
@media only screen and ( max-width: 500px ){  /* example */
  .file_upload > #file-placeholder{
    display: none
  }
  .file_upload > #file-button{
    width: 100%
  }
}
.file_upload input[type=file]{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(20);
  letter-spacing: 10em;     /* IE 9 fix */
  -ms-transform: scale(20); /* IE 9 fix */
  opacity: 0;
  cursor: pointer
}
/*-------------------------------*/

/*стили для sticky*/
#sticky-wrapper{
  width: 1170px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
}

.mini-logo{
  width: 0px;
}

.no-padding{
  padding: 0;
}

.is-sticky{
  /*
  .header__main-menu-nav::before{
    content: '';
    position: absolute;
    left: -100px;
    width: 100px;
    height: 60px;
    background-color: #f8f8f8;
    background-image: url(/assets/images/logo.png);
    background-size: 55%;
    background-repeat: no-repeat;
    background-position: 22px 3px;
  }
  */

  .header__main-menu-nav{

    ul{
      .last{
        position: relative;
        
        &:after {
          content: '+7 (495) 483-04-44';
          position: absolute;
          height: 60px;
          width: 215px;
          text-decoration: none;
          font-family: proxima_nova_ltsemibold;
          font-size: 20px;
          line-height: 20px;
          color: #FFF;
          padding: 22px 20px 18px;
          background-color: #0076fe;
          box-sizing: border-box;
        }
      }

      ul{
        .last{
          &:after {
            display: none;
          }
          ul{
            .last{
              &:after {
                display: none;
              }
              ul{
                .last{
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

    }
  }



  .mini-logo{
    width: 100px;
    height: 60px;
    background-color: #f8f8f8;
    background-image: url(/assets/images/logo.png);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position: 23px 3px;
    transition: width .7s;
    padding: 0;
    display: block;

    &:after{
      content:'';
    }
  }

  .header__main-menu-nav-toup{
    //display: block;
    opacity: 1;
    transition: 1s;
  }
}
/*----------------*/

.top-line{
  width: 300%;
  height: 1px;
  background-color: #efbbbb;
  box-shadow: 0 0 6px 2px #efbbbb;
  position: relative;
  z-index: 990;
  -webkit-transform: rotate(-17deg);
  transform: rotate(-22deg);
  top: -19px;
  left: -1180px;

  /*сдвиг вниз, эксперимент*/
  top: 11px;
}

.bottom-line{
  width: 300%;
  height: 1px;
  background-color: #efbbbb;
  box-shadow: 0 0 6px 2px #efbbbb;
  position: relative;
  z-index: -1;
  -webkit-transform: rotate(-17deg);
  transform: rotate(-22deg);
  left: -1180px;
  top: -330px;
}


/*большие цифры для блока "схема нашей работы"*/
.big-one::before{
  content:'1';
}
.big-two::before{
  content:'2';
}
.big-three::before{
  content:'3';
}
.big-four::before{
  content:'4';
}

.big-one::before, .big-two::before, .big-three::before, .big-four::before{
  font-family: 'proxima_nova_ltsemibold';
  font-size: 120px;
  color: #d2e7ff;
  position: absolute;
  z-index: -1;
  left: -20px;
}

/*форма в контактах*/
.contacts-form{
  height: 700px !important;

  textarea{
    height: 100px;
    padding-top: 10px;
  }
}

.fleft{
  float: left;
}

.left-padding{
  padding-left: 30px;
}

.no-padding{
  padding: 0px !important;
}

/*стили для пагинации*/
.pagination {


  li{
    float: left;
  }

  li a{
    font-family: 'proxima_nova_ltsemibold';
    font-size: 20px;
    line-height: 28px;
    color: #4a90e2;
    margin-right: 20px;
  }

  .active a{
    background-color: #4a90e2;
    color: #fff;
    text-decoration: none;
    padding: 3px 10px 3px 10px;
  }

  .disabled, .control{
    display: none;
  }
  /*вывод без пагинации(лежал в чанке news)*/
  /*
  [[!pdoResources?
    &parents=`43`
    &depth=`1`
    &limit=`10`
    &tpl=`newsTpl`
  ]]
  */
}


/*стили для slick*/
.slick-prev {
  left: 0px !important;
  z-index: 1;
}
.slick-next {
  right: 18px!important;
  z-index: 1;
}

.slick-prev:hover, .slick-next:hover {
  opacity: 0.75 !important;
}

.slick-prev:before {
  content: '' !important;
  font-family:FontAwesome !important;
  font-size: 20px;
  line-height: 1;
  opacity: 1 !important;
  color: #000 !important;
  padding: 12px 14px 10px 10px;
  background-color: #fff;
}
.slick-next:before {
  content: '' !important;
  font-family:FontAwesome !important;
  font-size: 20px;
  line-height: 1;
  opacity: 1 !important;
  color: #000 !important;
  padding: 12px 10px 10px 14px;
  background-color: #fff;
}


/*стили для увеличителя фоток*/
.qb-inside{
  height: auto !important;
}


.incorrect-value{
  font-size: 11px;
  position: relative;
  top: -15px;
  margin-bottom: -13px;
  color: red;
  display: none;
}

.error-input{
  border: 2px solid red !important;
}

.header{
  height:170px;
  background-color:#f8f8f8;
  position:relative;
  top:-16px;

  .header_icon{
    width: 50px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid #e4e4e4;
    padding: 0;
    text-align: center;
    text-decoration: none;
    display: block;

    i{
      font-family:FontAwesome;
      color:#1b85ff;
      font-style: normal;
      font-size: 25px;
      position: relative;
      top: 12px;
    }
  }

  &__container{
    width: 1170px;
    margin: 0 auto;
    height: 110px;
    position: relative;

    div{
      display: inline-block;
      height: 110px;
      float: left;
      position: relative;
      
      table{
        float: left;
      }
    }

    &-logo {
      width: 125px;
      background-color: #fff;
      box-sizing: border-box;
      /*тень справа от блока*/
      background-image: url(/assets/images/shadow.png);
      background-position: right;
      background-repeat: repeat-y;
      background-position-x: 119px;
      /*--------------------*/
      position: relative;

      &-img{
        height: 80px;
        position: absolute;
        top: 23px;
        left: 18px;
      }
    }

    &-infoblock1{
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      width: 245px;
      box-sizing: border-box;
      padding-left: 40px;
      padding-top: 39px;
      /*тень справа от блока*/
      background-image: url(/assets/images/shadow.png);
      background-position: right;
      background-repeat: repeat-y;
      /*--------------------*/
    }

    &-infoblock2{
      //width: 470px;
      width: 245px;
      box-sizing: border-box;
      padding-left: 15px;
      padding-top: 33px;
      /*тень справа от блока*/
      background-image: url(/assets/images/shadow.png);
      background-position: right;
      background-repeat: repeat-y;
      /*--------------------*/

      &:hover {
        table {
          opacity: .6;
        }
      }

      &-onlinecalc{
        margin-right:30px;

        &-text {
          font-family: 'proxima_nova_ltsemibold';
          font-size: 14px;
          margin-left: 15px;
          display: block;
        }
      }
    }

    &-infoblock3{
      box-sizing: border-box;
      padding-top: 30px;
      margin-left: 15px;

      &:hover{
        a {
          text-decoration: none;
        }
      }

      &-phone{
        font-family: 'proxima_nova_rgregular';
        font-size: 28px;
        position: relative;
        top: 1px;
        left: 20px;
      }
      &-callback{
        font-family: 'proxima_nova_ltsemibold';
        font-size:16px;
        color:#fa3e31;
        position: relative;
        top: -5px;
        left: 20px;
      }

      .work-time{
        position: absolute;
        left: 81px;
        bottom: 8px;
        font-size: 12px;
      }
    }
  }

  &__main-menu-nav{
    width: 1170px;
    margin: 0 auto;
    height: 60px;
    background-color: #1b85ff;

    &-toup{
      position: fixed;
      right: 20px;
      bottom: 20px;
      background-color: #4a90e2;
      color: #fff;
      padding: 10px 15px 10px 15px;
      font-size: 17px;
      //display: none;
      cursor: pointer;
      opacity: 0;
      transition: 1s;

      &-up{
        font-family:FontAwesome;
        color:#fff;
      }
    }

    &-toup:hover{
      opacity: 0.75;
    }

    ul{
      list-style-type: none;
      position: relative;

      li{
        float: left;
        background-color: #1b85ff;
        position: relative;

        a{
          text-decoration: none;
          font-family: 'proxima_nova_ltsemibold';
          font-size: 16px;
          line-height: 20px;
          color: #FFF;
          padding: 22px 20px 18px;
          display: inline-block;
        }

        ul{
          display:none;
          list-style-type: none;
          position: absolute;
          left: 0;
          top: 60px;
          width: 100%;
          background-color: #1b85ff;
          width: 250px;
          z-index: 1000;

          li{
            float: left;
            width: 250px;

                  a{
                    text-decoration: none;
                    font-family: 'proxima_nova_ltsemibold';
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFF;
                    //padding: 22px 20px 18px;
                    padding: 10px 10px 10px 18px;
                    display: inline-block;
                  }

                  ul{
                    display:none;
                    list-style-type: none;
                    position: absolute;
                    left: 250px;
                    top: 0;
                    width: 250px;
                    background-color: #1b85ff;
                    box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.75);
                    z-index: 1001;

                    li{
                      float: left;
                      width: 250px;


                            a{
                              text-decoration: none;
                              font-family: 'proxima_nova_ltsemibold';
                              font-size: 16px;
                              line-height: 20px;
                              color: #FFF;
                              //padding: 22px 20px 18px;
                              padding: 10px 10px 10px 18px;
                              display: inline-block;
                            }

                            ul{
                              display:none;
                              list-style-type: none;
                              position: absolute;
                              left: 250px;
                              top: 0;
                              width: 250px;
                              background-color: #1b85ff;
                              box-shadow: 0px 0px 27px -10px rgba(0,0,0,0.75);
                              z-index: 1002;

                              li{
                                float: left;
                                width: 250px;
                              }

                              .mini-logo{
                                display: none !important;
                              }
                            }



                    }

                    .mini-logo{
                      display: none !important;
                    }
                  }



          }

          .mini-logo{
            display: none !important;
          }
        }
      }
    }

    ul li:hover{
      background-color: #156fd7;
      ul{
        display:block;

        li{
          ul{
            display: none;
          }
        }
      }
    }

    ul li ul li:hover{
      background-color: #156fd7;
      ul{
        display:block;

        li{
          ul{
            display: none;
          }
        }
      }
    }

    ul li ul li ul li:hover{
      background-color: #156fd7;
      ul{
        display:block;
      }
    }


  }
}

.body-container{
  width: 1170px;
  margin: 0 auto;

  &-page-h1{
    font-family: 'proxima_nova_ltsemibold';
    font-size: 50px;
    padding-left: 30px;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  &__start-text{
    padding-left: 30px;
    font-family: 'proxima_nova_rgregular';
    font-size: 16px;
    line-height: 28px;
    width: 719px;
  }

  &__after-text{
    height: 295px;
    background-color: #f6f6f5;
    margin-top: 61px;
    box-sizing: border-box;
    padding: 37px 0px 0 35px;
    position: relative;

    &-grid{
      width:1030px;

      &-container{
        float:left;
        width: 32%;
        box-sizing: border-box;
        margin-right: 10px;
        margin-bottom: 40px;

        &-icon{
          margin-right: 15px;

          img{
            width: 80px;
          }
        }

        &-header{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 20px;
        }

        &-text{
          font-family: 'proxima_nova_rgregular';
          font-size: 14px;
        }
      }
    }

    &-buttons{
      width: 140px;
      height: 294px;
      position: absolute;
      right: 0;
      top: 0px;

      &-request{
        width: 140px;
        height: 147px;
        background-color: #fa3e31;
        display: block;
        text-decoration: none;
        text-align: center;
        position: relative;

        &-icon{
          font-family: FontAwesome;
          font-size: 25px;
          padding-top: 8px;
          color: #fff;
          width: 30px;
          height: 33px;
          position: absolute;
          border: 3px solid #fff;
          border-radius: 5px;
          top: 15px;
          left: 50px;
        }

        &-text{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 18px;
          color: #fff;
          position: relative;
          top: 73px;
        }
      }

      &-pricelist{
        width: 140px;
        height: 147px;
        background-color: #000000;
        display: block;
        text-decoration: none;
        text-align: center;
        position: relative;

        &-icon{
          font-family: FontAwesome;
          font-size: 25px;
          padding-top: 8px;
          color: #fff;
          width: 30px;
          height: 33px;
          position: absolute;
          border: 3px solid #fff;
          border-radius: 5px;
          top: 15px;
          left: 50px;
        }

        &-text{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 16px;
          color: #fff;
          position: relative;
          top: 73px;
        }
      }
    }
  }

  &__thesis{
    padding-left: 30px;
    margin-top: 55px;
    display: inline-block;
    position: relative;

    &-block{
      width: 255px;
      float: left;
      margin-right: 85px;

      &-header{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 28px;
        margin-bottom: 25px;
        display: inline-block;
      }
      &-text{
        font-family: 'proxima_nova_rgregular';
        font-size: 16px;
        line-height: 28px;

        &-garant{
          margin-top: 35px;

          &-header{
            font-family: 'proxima_nova_ltsemibold';
            font-size: 42px;
            color:#1b85ff;
          }

          li{
            font-family: 'proxima_nova_rgregular';
            font-size: 20px;
            margin-bottom: 35px;
          }
        }
        &-rezka{

          li{
            margin-bottom: 25px;
            position: relative;
          }
          li:before {
            color: #d8d8d8;
            content: "• ";
            font-size: 35px;
            position: absolute;
            top: -3px;
            left: -20px;
          }
        }
      }
    }

    &-cutter-opacity{
      background-image: url(/assets/images/cutter-opacity.png);
      background-repeat: no-repeat;
      background-size: 89%;
      width: 155px;
      height: 355px;
      position: absolute;
      right: -105px;
      top: -62px;
      z-index: -1;

      /*сдвиг вниз, эксперимент*/
      background-size: 97%;
      width: 155px;
      height: 390px;
    }
  }

  &__equipment{
    display: inline-block;

    /*сдвиг вниз, эксперимент*/
    margin-bottom: -35px;

    &-photo{
      /*
      width: 710px;
      height: 510px;
      background-image: url('/assets/images/plasmacutter.png');
      background-position: -33px 0px;
      position: relative;
      float: left;
      left: -40px;
      */
      width: 710px;
      height: 510px;
      background-image: url(/assets/images/plasmacutter-crop.png);
      background-position: 14px -54px;
      background-size: 100%;
      position: relative;
      float: left;
      left: -40px;

      /*сдвиг вниз, эксперимент*/
      top: 30px;
      z-index: -1;

      &-germany{
        position: absolute;
        right: 15px;
        bottom: 190px;
      }
    }

    &-desc{
      float: left;
      width: 430px;

      /*сдвиг вниз, эксперимент*/
      position: relative;
      top: 30px;


      &-text{
        display: block;
        margin-bottom: 35px;

        &-header{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 28px;
          display: block;
          margin-bottom: 15px;
          display: inline-block;
        }
        &-name{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 50px;
          display: block;
          margin-bottom: 15px;

          span{
            font-family: 'proxima_nova_ltsemibold';
          }
        }
        &-text{
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 28px;
        }
      }

      &-partners{

        &-header{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 28px;
          display: block;
          margin-bottom: 30px;
          display: inline-block;
        }

        &-container{

          &-block{
            display: block;
            //width: 70px;
            //height: 70px;
            float: left;
            //background-color: #00AA00;
            margin-right: 35px;
            margin-bottom: 35px;
          }
          &-block:last-child{
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  &__samples{


    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 28px;
      display: block;
      margin-bottom: 25px;
    }

    &-container{


      &-gallery{
        float: left;

      }

      &-form{
        width: 420px;
        height: 465px;
        display: block;
        background-color: #f6f6f5;
        float: right;
        padding: 30px 0px 0 30px;
        box-sizing: border-box;
        position: relative;

        &-header{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 24px;
          margin-bottom: 18px;
        }
        &-inputlabel{
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          color: #9b9b9b;
          margin-bottom: 10px;
        }
        &-input{
          width: 350px;
          height: 50px;
          box-sizing: border-box;
          font-family: 'proxima_nova_rgregular';
          font-size: 20px;
          padding: 0px 17px 0px 17px;
          border: none;
          margin-bottom: 18px;
        }
        &-submit{
          background-color: #4a90e2;
          text-decoration: none;
          color: #fff;
          padding: 14px 30px 14px 30px;
          font-family: 'proxima_nova_ltsemibold';
          font-size: 18px;
          margin-top: 20px;
          display: inline-block;
        }

        &::before{
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          border-width: 0 45px 45px 0;
          border-style: solid;
          border-color: #d8d8d8 #fff;
          transform: rotate(90deg);
        }
      }
    }
  }

  &__thanks{
    display: inline-block;
    width: 100%;
    margin-top: 60px;

    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 28px;
      margin-bottom: 35px;
    }
    &-container{
      float: left;
      width: 165px;
      margin-right: 36px;

      &-text{
        font-family: 'proxima_nova_rgregular';
        font-size: 13px;
        margin-top: 10px;
      }
    }
    &-container:last-child{margin-right: 0px}
  }

  &__shema{
    display: inline-block;
    width: 100%;
    margin-top: 45px;
    /*изогнутая линия для цифр*/
    background-image: url('/assets/images/line.png');
    background-repeat: no-repeat;
    background-position: 23px 116px;

    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 28px;
      margin-bottom: 70px;
      text-align: center;
    }
    &-container{
      width: 215px;
      font-family: 'proxima_nova_rgregular';
      font-size: 20px;
      line-height: 26px;
      margin-right: 100px;
      float: left;
      position: relative;
    }
    &-container:last-child{
      margin-right: 0px;
    }
  }

  &__uslugi{
    display: inline-block;
    width: 100%;
    margin-top: 45px;

    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 28px;
      margin-bottom: 70px;
      text-align: center;
    }
    &-container{
      width: 210px;
      margin-right: 110px;
      float: left;

      &-image{
        margin-right: 15px;
      }
      &-text{
        font-family: 'proxima_nova_rgregular';
        font-size: 20px;
        line-height: 20px;
        white-space: nowrap;
      }
    }
    &-container:last-child{
      margin-right: 0px;
    }
  }

  &__uslugi-tabs{
    margin-top: 50px;

    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 34px;
      margin-bottom: 35px;
      text-align: center;
    }

    &-container{


      &-tabs{

        a{
          color:#4a90e2;

          &:hover{
            text-decoration: none;
          }
        }

        &-rezka, &-gibka, &-svarka, &-pokraska{
          display: inline-block;
          float: left;
          margin-right: 32px;
          padding: 18px;
          border-radius: 10px;
          border-bottom: 8px solid transparent;
          border-top: 3px solid transparent;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
        }
        /*
        &-rezka{

        }
        &-gibka{

        }
        &-svarka{

        }
        */
        &-pokraska{
          margin-right: 0px;
        }


        .active-tab{
          border-top: 3px solid #f9f9f9;
          border-left: 3px solid #f9f9f9;
          border-right: 3px solid #f9f9f9;
          background-color: #fff;

          border-bottom: 3px solid #FFF;
          z-index: 1;
          position: relative;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;


          a{
            color: #000;
            text-decoration: none;
            font-weight: bold;
            cursor: default;
          }
        }
      }

      &-panels{
        width: 1154px;
        box-sizing: border-box;
        display: inline-block;
        padding: 20px 0 35px 23px;
        border-top: 3px solid #f9f9f9;
        border-left: 3px solid #f9f9f9;
        border-right: 3px solid #f9f9f9;
        border-bottom: 3px solid #f9f9f9;
        border-radius: 10px;
        margin-top: -10px;
        position: relative;
        box-shadow: 0px 5px 9px -7px rgba(0,0,0,.75);

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: none;


        &-rezka, &-gibka, &-svarka, &-pokraska{
          display: none;

          &-bottomtext{
            font-family: 'proxima_nova_ltsemibold';
            font-size: 20px;
            float: left;
            margin-right: 63px;
          }
          &-bottomtext::before{
            content:'';
            font-family:FontAwesome;
            font-size: 24px;
            color:#79bc19;
            margin-right: 6px;
          }
          &-bottomtext:last-child{
            margin-right: 0px;
          }
        }
        &-gibka{

        }
        &-svarka{

        }
        &-pokraska{

        }

        .active-panel{
          display: block;
        }
      }
    }
  }

  &__why-we{
    margin-top: 52px;

    &-header{
      text-align: center;
      margin-bottom: 42px;

      &-text{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 28px;
        border-bottom: 5px solid #ffcb00;
        padding-bottom: 2px;
      }
    }

    &-text{
      font-family: 'proxima_nova_rgregular';
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__contacts{
    padding: 0 30px 0 30px;

    &-map {
      float: left;

      &-header {
        font-family: 'proxima_nova_ltsemibold';
        font-size: 20px;
        margin-bottom: 15px;
        clear:both
      }

      &-phone {
        float: left;
        margin-right: 50px;
        margin-bottom: 25px;

        &-header {
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 28px;
          color: #8b8b8b;
        }
        &-text {
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 28px;
        }
        &-text::before {
          content: '';
          font-family: FontAwesome;
          color: #1b85ff;
          font-size: 20px;
          margin-right: 10px;
        }

        .email::before{
          content: '';
        }
      }

      &-input{
        width: 246px;
        font-family: proxima_nova_rgregular;
        font-size: 18px;
        padding: 9px 0 8px 17px;
        border: 1px solid #e3e3e3;
        display: none;
      }

      &-do-way{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 16px;
        line-height: 28px;
        color:#4a90e2;
        border-bottom: 1px dashed #4a90e2;
        text-decoration: none;
      }

      &-adress{
        font-family: 'proxima_nova_rgregular';
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      &-adress::before{
        content: '';
        font-family:FontAwesome;
        color: #1b85ff;
        font-size: 20px;
        margin-right: 10px;
      }

      &-button{
        display: none;
        font-size: 13px;
        background-color: #1b85ff;
        color: #fff;
        padding: 12px 15px 12px 15px;
        font-weight: bold;
        position: relative;
        top: -2px;
        margin-left: 10px;
        cursor: pointer;
      }
      &-button:hover{
        opacity: 0.7;
      }
    }

    &-form{
      float: right;
    }
  }

  &__about-promo{
    position: relative;
    left: 83px;

    .body-container__after-text-grid-container{
      margin-bottom: 35px;
    }
  }

  &__gendir{
    width: 100%;
    height: 855px;
    display: inline-block;
    background-image: url('/assets/images/gendir.jpg');
    background-repeat: no-repeat;
    background-position: -105px 5px;
    position: relative;

    &-photo{


      &-name{
        height: 0;
        width: 272px;
        border-top: 100px solid #1b85ff;
        border-right: 60px solid transparent;
        position: absolute;
        top: 480px;

        span{
          font-family: proxima_nova_rgregular;
          font-size: 16px;
          line-height: 28px;
          color: #fff;
          position: relative;
          top: -78px;
          left: 35px;
        }
        span:first-child{
          opacity: 0.7;
        }
      }

      &-maincontainer {
        position: absolute;
        width: 500px;
        height: auto;
        bottom: 80px;
        left: 230px;

        &-container {
          float: left;
          margin-right: 30px;

          &-shield {
            width: 131px;
            height: 147px;
            background-color: #f6f6f5;
            text-align: center;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 15px;
            box-sizing: border-box;


            &-text {
              font-family: 'proxima_nova_ltsemibold';
              font-size: 15px;
              line-height: 17px;
            }
          }
          &-shield::after {
            content: '';
            border: 65px solid transparent;
            border-top: 20px solid #f6f6f5;
            position: absolute;
            top: 147px;
            left: 0;
          }
        }
      }
    }

    &-text{
      width: 422px;
      height: 540px;
      background-color: #f6f6f5;
      display: inline-block;
      float: right;
      box-sizing: border-box;
      padding: 40px 35px 0px 35px;
      margin-top: 30px;

      &-header{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 28px;
        margin-bottom: 30px;
      }

      &-text{
        font-family: 'proxima_nova_rgregular';
        font-size: 16px;
        line-height: 28px;
      }
    }

    &-partners{
      width: 405px;
      position: absolute;
      bottom: 0;
      right: 15px;

      &-header{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 28px;
        margin-bottom: 20px;
      }
    }
  }


  &__prices{


    &-table{
      display: inline-block;
      float: left;

      &-header{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 34px;
        line-height: 50px;
        margin-bottom: 25px;
      }

      &-table{


        table {
          border-collapse: collapse;
        }

        td{
          border: 1px solid #e1e1e1;
          text-align: center;
          vertical-align: middle;
          font-family: 'proxima_nova_rgregular';
          font-size: 13px;
          line-height: 28px;
          padding: 5px 27px 5px 27px;
        }

        &-fill{
          background-color: #fbfbfb;
        }

        .bold-font td{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 14px;
          line-height: 28px;
        }
      }
    }

    &-info{
      display: inline-block;
      float: left;
      margin-left: 50px;
      padding-top: 17px;

      &-more a{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 16px;
        line-height: 28px;
        color:#4a90e2;

        &:hover{
          text-decoration: none;
        }
      }

      &-download{
        width:279px;
        height: 89px;
        display: inline-block;
        box-sizing: border-box;
        border: 2px solid #e9e9e9;
        padding: 16px 0px 0px 20px;
        margin-top: 30px;

        &-icon{
          width: 50px;
          height: 50px;
          border-radius: 30px;
          padding: 0;
          text-align: center;
          text-decoration: none;
          display: block;
          background-color: #1b85ff;
          float: left;
          margin-right: 12px;

          i{
            font-family: FontAwesome;
            color: #fff;
            font-style: normal;
            font-size: 25px;
            position: relative;
            top: 14px;
          }
        }

        &-text{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 16px;
          line-height: 19px;
          float: left;
          margin-top: 6px;
          color: #000;
        }
      }

      &-list{
        margin-top: 35px;
      }
    }
  }

  &__news{


    &-container{
      display: inline-block;
      margin-bottom: 30px;

      &-date{
        float: left;
        text-align: center;
        cursor: pointer;
        width: 73px;
        height:93px;
        margin-top: 5px;
        box-sizing: border-box;
        background-image: url('/assets/images/gray.png');

        &-date{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 40px;
          margin-top: 7px;
        }
        &-text{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 16px;
        }
      }

      &-text{
        float: left;
        margin-left: 20px;

        &-header{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 20px;
          color: #4a90e2;
          margin-bottom: 11px;
          display: inline-block;
        }
        &-text{
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 28px;
          width: 1044px;
        }
      }
    }
  }


  &__full-news{


    &-image{


      img{
        width: 231px;
        height: 231px;
        float: left;
        clear: right;
        margin-right: 30px;
      }
    }

    &-text{
      font-family: 'proxima_nova_rgregular';
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__more-news{


    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 34px;
      line-height: 50px;
      margin-bottom: 25px;
      margin-top: 35px;
    }

    &-block{
      width: 360px;
      height: 130px;
      margin-right: 25px;
      box-sizing: border-box;
      float: left;
      border: 2px solid #f1f1f1;
      padding: 15px 25px 15px 25px;
      overflow: hidden;

      &-header{
        margin-bottom: 6px;

        a {
          font-family: 'proxima_nova_ltsemibold';
          font-size: 16px;
          line-height: 28px;
          color: #4a90e2;
        }
      }

      &-text{
        font-family: proxima_nova_rgregular;
        font-size: 13px;
        line-height: 22px;
        height: 70px;
        overflow: hidden;
        //text-overflow: ellipsis;
      }
    }

    &-block:last-child{
      margin-right: 0px;
    }
  }

  &__articles{


    &-block{
      width: 370px;
      height: 295px;
      margin-right: 30px;
      float: left;
      background-color: #fdfdfd;
      box-sizing: border-box;
      //padding: 35px 30px 15px 35px;
      margin-bottom: 35px;
      position: relative;

      &-header{
        /*
        font-family: 'proxima_nova_ltsemibold';
        font-size: 20px;
        line-height: 28px;
        color: #4a90e2;
        */

        font-family: proxima_nova_ltsemibold;
        font-size: 20px;
        line-height: 28px;
        color: #4a90e2;
        margin-top: 20px;
        margin-left: 35px;
        display: block;
        margin-bottom: 12px;
      }

      &-text{
        /*
        font-family: 'proxima_nova_rgregular';
        font-size: 16px;
        line-height: 28px;
        margin-top: 10px;
        margin-bottom: 40px;
        */

        font-family: proxima_nova_rgregular;
        font-size: 16px;
        line-height: 28px;
        //margin-top: 10px;
        margin-bottom: 25px;
        margin-left: 35px;
        width: 300px;
        //height: 145px;
        overflow: hidden;
      }

      &-date{
        /*
        font-family: 'proxima_nova_rgregular';
        font-size: 13px;
        line-height: 28px;
        color: #8c8c8c;
        */

        font-family: proxima_nova_rgregular;
        font-size: 13px;
        line-height: 28px;
        color: #8c8c8c;
        margin-left: 35px;
        position: absolute;
        bottom: 10px;
      }

      &-image{
        //margin-bottom: 20px;
        margin-bottom: 15px;

        img[src*="assets/images/"] {
          width: 370px;
          height: 192px;
        }
      }

      &-headerboth{
        font-family: 'proxima_nova_ltsemibold';
        font-size: 20px;
        line-height: 28px;
        color: #4a90e2;
        margin-left: 35px;
      }

      &-dateboth{
        font-family: 'proxima_nova_rgregular';
        font-size: 13px;
        line-height: 28px;
        color: #8c8c8c;
        margin-left: 35px;
        margin-top: 8px;
      }
    }

    &-block:nth-child(3n+3){
      margin-right: 0px;
    }
  }

  &__devices{


    &-container{
      display: inline-block;
      margin-bottom: 65px;

      &-image{
        float: left;
        width: 630px;
        height:350px;
        position: relative;
        box-sizing: border-box;
        border: 3px solid #1b85ff;

        &-germany{
          position: absolute;
          top: 28px;
          left: 28px;
        }

        img{
          position: absolute;
          bottom: 0;
          left: -75px;
        }
      }

      &-text{
        float: left;
        margin-left: 50px;
        padding-top: 10px;

        &-header{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 50px;
          line-height: 20px;
          margin-bottom: 25px;

          span{
            font-family: 'proxima_nova_ltsemibold';
            color:#d73247;
          }
        }

        &-text{
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 28px;
          width: 407px;
          margin-bottom: 17px;
        }

        &-more{
          font-family: 'proxima_nova_ltsemibold';
          font-size: 16px;
          line-height: 28px;
          color:#4a90e2;
        }
      }
    }
  }
}

.footer{
  width: 1170px;
  margin: 0 auto;
  height: 250px;
  margin-top: 80px;

  a{

    &:hover{
      text-decoration: none;
    }
  }

  &__menu{


    &-about{
      float: left;
      width: 120px;

      &-header{
        color:#808080;
        font-family: 'proxima_nova_ltsemibold';
        font-size: 16px;
        margin-bottom: 10px;
      }
      &-links{

        a{
          color:#1b85ff;
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    &-uslugi{
      float: left;
      margin-left: 80px;

      &-header{
        color:#808080;
        font-family: 'proxima_nova_ltsemibold';
        font-size: 16px;
        margin-bottom: 10px;
      }
      &-links{
        float: left;
        width: 100px;
        text-align: left;
        margin-right: 130px;

        a{
          white-space: nowrap;
          color:#1b85ff;
          font-family: 'proxima_nova_rgregular';
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }

  &__copy{
    position: relative;
    left: -20px;
    float: right;

    &-copy{
      font-family: 'proxima_nova_rgregular';
      font-size: 16px;
    }
    &-dev{
      font-family: 'proxima_nova_rgregular';
      font-size: 14px;
      color:#6b6b6b;
      margin-top: 15px;

      &-ida{
        color:#000;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}

.loader{
  height:370px;
  overflow:hidden;
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  display:none;
  width:100%;
  border-top: 2px solid #e1e1e1;
  padding-top: 30px;
  background-color: #fff;
  z-index: 777;

  &__container{
    width: 1170px;
    margin: 0 auto;
    padding-left: 35px;

    &-readnext{
      color:#4a90e2;
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      margin-bottom: 30px;

      &-icon{
        //font-family:FontAwesome;
        //font-size: 20px;
        //background-image:url(/assets/images/reload.gif);
        position: relative;
        top:8px;
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
      }
    }

    &-header{
      font-family: 'proxima_nova_ltsemibold';
      font-size: 50px;
    }

    &-text{
      font-family: 'proxima_nova_rgregular';
      font-size: 18px;
      line-height: 27px;
      width: 719px;
      margin-top: 30px;
    }
  }
}

.fullwidth-container{
  width:100%;
  height:450px;
  display: inline-block;
  margin-top: -16px;

  background-color: #000;

  &-slide {

    &-image {
      background-image: url(/assets/images/3d-cutter.png);
      width: 1500px;
      height: 450px;
      background-repeat: no-repeat;
      //background-position: 25px;
      background-position: 0px;
      position: relative;
      margin: 0 auto;
      //margin-top: -450px;
      z-index: 1;
      opacity: 0.7;

      //top: -22px;
    }

    &-centered {
      width: 1170px;
      margin: 0 auto;
      position: relative;
      height: 100%;
      z-index: 2;

      &-header {

        &-layout {
          height: 0;
          width: 420px;
          border-top: 100px solid #000000;
          border-right: 60px solid transparent;
          opacity: 0.3;
          position: absolute;
          top: 205px;
        }

        &-text {
          font-family: 'proxima_nova_ltsemibold';
          font-size: 28px;
          position: absolute;
          top: 220px;
          left: 24px;
          color: #fff;
        }
      }

      &-text {
        font-family: 'proxima_nova_rgregular';
        font-size: 21px;
        color: #fff;
        position: absolute;
        top: 340px;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, .4), 0 0 1em transparent;
      }
    }
  }
}

.mainclients{
  width: 100%;
  height: 283px;
  display: inline-block;
  margin-top: -16px;
  background-color: #f9f9f9;
  margin-top:75px;

  &__container{
    width: 1170px;
    margin: 0 auto;
    position: relative;
    height: 100%;

    &-header{
      margin-bottom: 35px;
      margin-top: 50px;
      text-align: center;

      &-text{
        font-family: proxima_nova_ltsemibold;
        font-size: 28px;
        border-bottom: 5px solid #ffcb00;
        padding-bottom: 2px;
      }
    }

    &-client{
      margin-right: 56px;
      float: left;
    }
    &-client:last-child{
      margin-right: 0px;
    }
  }
}

.is-sticky .header__main-menu-nav::before{
  content: '';
  position: absolute;
  //height: 80px;
  height: 60px;
  width: 250%;
  left: -160%;
  background-color: #1b85ff;
}
.is-sticky .header__main-menu-nav::after{
  content: '';
  position: absolute;
  //height: 80px;
  height: 60px;
  width: 200%;
  left: 60%;
  top: 0;
  background-color: #1b85ff;
  z-index: -1;
}

.header_infoline{
  display: none;
}

.is-sticky .header_infoline{
  display: inline-block;
  height: 20px;
  background-color: #F8F8F8;
  width: 181%;
  position: relative;
  margin-bottom: -5px;
  left: -50%;
  z-index: 1;
  text-align: center;
}

.is-sticky .header_infoline a{
  text-decoration: none;
  color: black;
  margin-right: 98px;
}

.is-sticky .header_infoline a:first-child{
  margin-right: 257px;
}

.il_cont{
  width: 1170px;
  position: relative;
  left: 50%;
  margin-left: -475px;
  text-align: left;
  padding-left: 120px;

}

/*
is-sticky .header_infoline::before{
  content: '';
  position: absolute;
  height: 20px;
  background-color: #F8F8F8;
  width: 50%;
  top:0;
  right: 0;
}

is-sticky .header_infoline::after{
  content: '';
  position: absolute;
  height: 20px;
  background-color: #F8F8F8;
  width: 50%;
  top:0;
  left: 0;
}
*/

.slick-slider {
  display: inline-block !important;
}

#ldr .body-container{
  opacity: 1 !important;
}

.vex-last{
  position: absolute;
  right: -30px;
  top: -15px;
  border-radius: 30px;
  width: 31px;
  height: 31px;
  padding: 0 !important;
  background-color: #fff !important;
  border: 3px solid #4a90e2 !important;
}

.vex-last::after{
  content: '';
  font-family: FontAwesome;
  font-size: 17px;
  color: #4a90e2;
  position: absolute;
  top: 3px;
  left: 6px;
}

#ldr a{
  color: #4a90e2;
  font-size: 18px;
  margin-bottom: 30px;
  text-decoration: none;
  border-bottom: none;
}

.vex-dialog-form #file-button{
  font-size: 18px;
  padding: 7px 0 0 12px;
}

.eachtab{
  cursor: pointer;
}

.slimScrollBar{opacity: 1 !important; background: rgb(123, 123, 123) !important;}
.slimScrollRail{width:1px !important; right:4px !important;}

.body-container__contacts-map-phones{
  width:640px;
}

.viber .body-container__contacts-map-phone-text::before{
  content: '';
  background-image: url(/assets/images/viber.png);
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-position: 0;
  position: relative;
  top: 5px;
}

.viber{
  clear: both;
}

.whatsapp .body-container__contacts-map-phone-text::before{
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-position: 0;
  position: relative;
  top: 5px;
  background-image: url(/assets/images/whatsapp.png);
}

.whatsapp{
  margin-right: 45px;
}

body.index{
  overflow-x: hidden;
}


//стили для дива с телефоном
/*
//div
    float: right;
    height: 60px;
    text-decoration: none;
    font-family: proxima_nova_ltsemibold;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    padding: 22px 20px 18px;
    display: inline-block;
    background-color: rgb(0, 118, 254);
    box-sizing: border-box;

//a>logo
    display:inline-block

//first li (где и будет сам див)
    height: 60px

*/

.social-buttons{
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  box-sizing: border-box;
  padding-left: 13px;
  padding-top: 11px;
  background-color: white;
  box-shadow: 3px 3px 9px 0px rgba(0,0,0,.20);

  ul {
    list-style-type: none;

    li {
      height: 35px;
      background-color: transparent;

      &:hover{
        background-color: transparent;
      }

      a {
        padding: 0 !important;
      }
    }
  }
}

//перенос стилей из style="..."

.rmdl{
  position: absolute;  height: 100%;  width: 100%;  left: -10px;  top: 0;  z-index: 100000;
}

.ib2a{
  position: absolute;height: 100%;width: 100%;left: -10px;top: 0;z-index: 100000;
}

.clbm{
  position: absolute;height: 100%;width: 118%;left: -25px;top: 0;z-index: 100000;
}

.low-price{
  height: 100px;
  width: 100%;
  background-color: #27374a;
  display: flex;
  margin-bottom: -61px;
  margin-top: 61px;
}

.low-price_img{
  background-color: #fff;
  border-radius: 50px;
  position: relative;
  top: 8px;
  left: 10px;
  width: 85px;
  height: 85px;
  margin-left: 15px;
  box-shadow: 0 2px 15px rgba(0,0,0,.34);
}

.lpi{
  width: 75px;
  position: absolute;
  top: 6px;
  left: 5px;
}

.glc{
  font-size: 40px;
  color: #fff;
  margin-left: 75px;
  margin-top: 26px;
  margin-right: -24px;
  text-shadow: 0 1px 15px rgba(0,0,0,.34);
}

.lprm{
  width: 350px;
  height: 50px;
  font-size: 20px;
  background-color: #fff;
  border-radius: 35px;
  padding-top: 13px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 24px;
  margin-left: 140px;
  color: #27374A;
  box-shadow: 0 4px 15px rgba(0,0,0,.34);
  text-decoration: none;
}

.pohp{width: 55%;}

.hpocc{margin-left: -20px;}

.tosp{font-size: 26px;}

.bctb{margin-right:100px}

.epmpei{padding-top:15%;width: 75% !important;padding-left: 3%;}

.bcbcf{margin-top: 70px;}

body.uslugi{overflow-x: hidden}

.scomp{padding-top: 25px;}

.gf1{background-color:#000000}
.gf2{background-color:#FF0000}
.gf3{background-color:#FFCC00}

.aiep{width: 60% !important;padding-left: 20%;padding-top: 5%;}

.aies{padding-top: 6px;}

.powtpt{padding-top:10% !important;width: 35% !important;padding-left: 33% !important;}

.opl{width:60%; height:60%}

.oplpoe{padding-top:18px;}

.oplwhp{width:90% !important; height:100% !important;padding-top:22px !important;}

.oplpb{padding-bottom: 6px;}

.ympis{width: 632px; height: 360px; position: relative; top: 25px;}

.aiit{width: 80px;}

.bcgttds{padding: 0 20px 0 0;}

.phs{height: 60px;}

.imnpn{margin:0; padding:0;}

.bpwpb{width:165px;padding: 5px;background: #f3f3f3;}

body{position: relative}

.pust{margin-top:25px;}

.bcedpcb{width: 100px}

.bcedpcbi{width: 100%}

.bcedpcbia{width: 100%; margin-top: 8px;}

.dnon{display: none}

#chatra.chatra--pos-middle:not(.chatra--expanded) {
  bottom: 30% !important;
}

.vexalert{

  .vex-dialog-message{
    position: relative;
    top: 12px;
    left: 9px;
  }

  button{

    &:after{
      top: 5px !important;
    }
  }
}

.dmtlr{
  margin-top: 0px !important;
}

.shema-order{
  margin: 0 auto;
  display: block;
  width: 73px;
  margin-top: 30px;
  transition: .7s;

  &:hover{
    transition: .7s;
    background-color: #1B85FF;
  }
}




